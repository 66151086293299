import { Injectable } from "@angular/core";
import { ICommunicationService } from "../../shared/abstract/communication.interface";
import { BehaviorSubject, Subject } from "rxjs";
import { ApplicationType } from "../../shared/models/application-type";
import { NpsCommunicationService } from "../../nps/services/nps-communication.service";
import { AccountInfo, EventError } from "@azure/msal-browser";
import { GpsCommunicationService } from "../../gps/services/gps-communication.service";

@Injectable({ providedIn: 'root' })
export class PortalCommunicationService implements ICommunicationService {

  public userLogin$ = new Subject<string>();//request from module to login
  public userLoginCompleted$ = new BehaviorSubject<AccountInfo | EventError | null>(null);//request from module to login

  public userLogout$ = new Subject<void>();//request from module to logout
  public userLogoutCompleted$ = new Subject<void>();//request from module to logout

  private app: Map<ApplicationType, ICommunicationService> = new Map<ApplicationType, ICommunicationService>();

  constructor(nps: NpsCommunicationService, gps: GpsCommunicationService) {
    this.app.set(ApplicationType.Nps, nps);
    this.app.set(ApplicationType.Gps, gps);
  }

  logoutAllApps(): Promise<void> {
    return new Promise((resolve, reject) => {

      //wait for all apps to logout
      let count = this.app.size;

      this.app.forEach((service) => {
        service.userLogoutCompleted$.subscribe(() => {
          count--;
          if (count == 0) {
            resolve();
          }
        });
      })

      //send logout request to all apps
      this.app.forEach((service) => {
        service.userLogout$.next();
      })

      //if no apps inited, resolve
      setTimeout(() => {
        resolve();
      }, 2000);

    });
  }

  //register(app: ApplicationType, service: ICommunicationService) {
  //  console.log('PortalCommunicationService register', app);
  //  this.app.set(app, service);
  //};
}
