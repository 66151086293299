import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { ICommunicationService } from "../../shared/abstract/communication.interface";
import { AccountInfo, EventError } from "@azure/msal-browser";

@Injectable({ providedIn: 'root' })
export class GpsCommunicationService implements ICommunicationService {

  public userLogin$ = new Subject<string>();//request from module to login
  public userLoginCompleted$ = new BehaviorSubject<AccountInfo | EventError | null>(null);//request from module to login

  public userLogout$ = new Subject<void>();//request from module to logout
  public userLogoutCompleted$ = new Subject<void>();//request from module to logout

  constructor() {

  }
}
